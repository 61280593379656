import { ptBR } from '@mui/x-data-grid';
import { ptBR as coreptBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
export const theme = createTheme(
  {
    palette: {
      type: 'light',
      primary: {
        main: '#0d46a0',
        background1: '#808f85ff',
        background2: ' #92898aff',
        error: '#CC2936',
        warning: '#FF785A',
        success: '#0B7189',
      },
      secondary: {
        main: '#4caf50',
        contrastText: '#fafafa',
      },
    },
  },
  ptBR,
  coreptBR
);
