import { ArrowBack, KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../api/api';
import Spinner from '../../components/Spinner/Spinner';
import { theme } from '../../theme/theme';

function Comissoes() {
  const [listaComissoes, setListaComissoes] = useState('');

  const isMobile = useMediaQuery('(min-width:800px)');

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    api
      .post('/vendas/comissao', { vendedor: 1 })
      .then(({ data }) => {
        console.log(data);
        setListaComissoes(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  function DetailsMobile(props) {
    const { comissao } = props;
    const [openDetails, setOpenDetails] = useState(false);
    return (
      <>
        <Grid sm={3} md={12} lg={12} ml={2} mt={1}>
          <IconButton onClick={() => setOpenDetails(!openDetails)}>
            <KeyboardArrowDown />
          </IconButton>
        </Grid>
        <Collapse in={openDetails} timeout={'auto'}>
          <Grid ml={1} sm={12} md={12} lg={12} mt={1} mb={1}>
            <Grid>
              <Typography variant="subtitle1">Cliente</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle2">{comissao.cliente}</Typography>
            </Grid>
          </Grid>
        </Collapse>
      </>
    );
  }
  function DetailsDesktop(props) {
    const { comissao } = props;

    return (
      <>
        <Grid ml={1} sm={12} md={12} lg={12} mt={1} mb={1}>
          <Grid>
            <Typography variant="subtitle1">Cliente</Typography>
          </Grid>
          <Grid>
            <Typography variant="subtitle2">{comissao.cliente}</Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  function RenderListaComissoes() {
    const total = listaComissoes.reduce(
      (acc, crr) => acc.comissao + crr.comissao
    );

    return (
      <>
        {listaComissoes ? (
          <>
            <Grid container component={Paper}>
              <Grid xs={12} sm={12} md={12} lg={12} mt={2}>
                <Grid>
                  <Typography variant="subtitle2" sx={{ textAlign: 'center ' }}>
                    Total de Comissões
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="h6" sx={{ textAlign: 'center ' }}>
                    {total.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Grid>
              </Grid>

              {listaComissoes.map((comissao) => {
                return (
                  <>
                    <Divider />
                    <Grid xs={2} sm={3} md={3} lg={3} ml={1} mt={1}>
                      <Grid>
                        <Typography variant="subtitle1">Doc.</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="subtitle2">
                          {comissao.documento}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid sm={3.5} md={3} lg={3} ml={1} mt={1}>
                      <Grid>
                        <Typography variant="subtitle1">Comissão</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="subtitle2">
                          {comissao.comissao.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid sm={3.5} md={3} lg={3} ml={2} mt={1}>
                      <Grid>
                        <Typography variant="subtitle1">Data</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="subtitle2">
                          {moment(comissao.data)
                            .add(3, 'hours')
                            .format('DD/MM/YYYY')}
                        </Typography>
                      </Grid>
                    </Grid>
                    {!isMobile ? (
                      <DetailsMobile comissao={comissao} />
                    ) : (
                      <DetailsDesktop comissao={comissao} />
                    )}
                  </>
                );
              })}
            </Grid>
          </>
        ) : (
          <>Nenhum Registro Encontrado </>
        )}
      </>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Button
        variant="contained"
        component={Link}
        to="/"
        size={'large'}
        startIcon={<ArrowBack />}
      >
        Voltar
      </Button>
      <RenderListaComissoes />
    </div>
  );
}

export default Comissoes;
