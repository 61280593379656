import React, { useEffect, useState } from 'react';
import api from '../../api/api';

import {
  Box,
  Modal,
  Typography,
  Grid,
  Divider,
  TextField,
  useMediaQuery,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
} from '@mui/material';

export default function ModalPedidoView(props) {
  const isMobile = useMediaQuery('(min-width:600px)');
  const { modalOpen, handleClose, dados } = props;
  const [loading, setLoading] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [codigovenda, setCodigovenda] = useState();

  useEffect(() => {
    setLoading(true);
    if (dados) {
      async function loadPedidos() {
        await api
          .post('pedidos/itenspedido', {
            pedido: dados.codigo_venda,
            cliente: '0',
            produto: '0',
          })
          .then((response) => {
            setPedidos(response.data);
          })
          .catch((err) => {
            alert('Erro ao buscar dados.');
          });
      }

      loadPedidos();
    }
    setLoading(false);
  }, [dados]);

  const style = isMobile
    ? {
        position: 'absolute',
        top: '50%',
        left: '50%',
        minWidth: '75%',
        maxWidth: '100%',
        height: '80%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        overflowY: 'auto',
        overflowX: 'hidden',
        boxShadow: 25,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',

        p: 4,
        m: 0,
      }
    : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        minWidth: '90%',
        maxWidth: '100%',
        height: '85%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        overflowY: 'auto',
        overflowX: 'hidden',
        boxShadow: 25,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        p: 4,
        m: 0,
      };

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            onClick={handleClose}
            style={{
              width: '25px',
              height: '25px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#ff5566',
              padding: '2px',
              marginBottom: '1%',
              borderRadius: '5px',
              boxShadow: '3px 3px #656565',
              color: '#ffff',
            }}
          >
            <Typography variant="h5">
              <strong>X</strong>
            </Typography>
          </div>
          {!dados ? (
            <h1>Sem Dados</h1>
          ) : (
            <>
              <Typography variant="h4">PEDIDO DE VENDA </Typography>
              <Divider />
              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                mt={5}
              >
                <Grid xs={12} sm={12} md={12} lg={1.5} mb={2} ml={1} mr={1}>
                  <TextField
                    id="codigo_venda"
                    label="Pedido Número"
                    variant="outlined"
                    value={dados.codigo_venda}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={1.5} mb={2} ml={1} mr={1}>
                  <TextField
                    id="pedido_interno"
                    label="Interno"
                    variant="outlined"
                    value={dados.codigo_venda_sv}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} mb={2} ml={1} mr={1}>
                  <TextField
                    id="razaosocial"
                    label="Razão Social"
                    variant="outlined"
                    value={dados.razao_social}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} mb={2} ml={1} mr={1}>
                  <TextField
                    id="status"
                    label="Status do Pedido"
                    variant="outlined"
                    value={dados.status}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={2} mb={2} ml={1} mr={1}>
                  <TextField
                    id="emissao"
                    label="Emissão do pedido"
                    variant="outlined"
                    value={dados.emissao}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={2} mb={2} ml={1} mr={1}>
                  <TextField
                    id="total"
                    label="Total do Pedido"
                    variant="outlined"
                    value={dados.total}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={7.2} mb={2} ml={1} mr={1}>
                  <TextField
                    id="total"
                    label="Observação"
                    variant="outlined"
                    value={dados.obs}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={7.2} mb={2} ml={1} mr={1}>
                  <TextField
                    id="total"
                    label="Motivo Cancelado"
                    variant="outlined"
                    value={dados.motivo_cancel}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Qtde</TableCell>
                      <TableCell>Valor Unitario</TableCell>
                      <TableCell>Valor Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pedidos.map((pedido, index) => {
                      return (
                        <>
                          <TableRow key={index}>
                            <TableCell>{pedido.descricao_produto}</TableCell>
                            <TableCell>{pedido.qtde}</TableCell>
                            <TableCell>
                              {pedido.valor.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {pedido.total.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
