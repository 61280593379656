import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes/routes.js';
import { Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext.js';
import history from './routes/history.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import { theme } from './theme/theme';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <Router history={history}>
            <ToastContainer autoClose={3000} />
            <Routes />
          </Router>
        </ConfirmProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
/**/
