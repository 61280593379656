import React, { useContext } from 'react';
import {
  Grid,
  useMediaQuery,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';
import Seller from '../../images/seller.svg';

import Order from '../../images/order.svg';
import Search from '../../images/search.svg';
import Schedule from '../../images/schedule.svg';
import Exit from '../../images/exit.svg';
import Replace from '../../images/replace.svg';
import Profit from '../../images/profit.svg';
import { Link } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { Context } from '../../contexts/AuthContext';

function Home() {
  const confirm = useConfirm();
  const { handleLogout, loading } = useContext(Context);
  const isMobile = useMediaQuery('(min-width:1000px)');

  const styleMainMenu = isMobile
    ? {
        maxWidth: '100%',
        minHeight: '400px',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }
    : {
        top: '50%',
        left: '50%',
        maxWidth: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      };

  const styleMainButton = isMobile
    ? {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1%',
        minHeight: '210px',
        maxHeight: '33%',
        height: '210px',
        width: '210px',
        maxWidth: '210px',
        borderRadius: '20px',
        margin: '7px',
      }
    : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        padding: '3%',

        alignItems: 'center',
        height: '17%',
        borderRadius: '20px',
      };

  const primaryPartButton = isMobile
    ? {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }
    : {
        width: '25%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
      };
  const secondPartButton = isMobile
    ? {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        mt: 2,
        color: 'white',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }
    : {
        width: '75%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
      };

  function confirmLogout() {
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja Sair do Sistema  ?',
      cancellationText: 'Cancelar',
      confirmationText: 'Aceitar',
    }).then(() => {
      handleLogout();
    });
  }
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Grid sx={styleMainMenu} component={Paper}>
      <Link to="/clientes" style={{ textDecoration: 'none' }}>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={3}
          mt={1}
          mb={1}
          bgcolor="primary.main"
          color="primary.contrastText"
          sx={styleMainButton}
        >
          <Grid sx={primaryPartButton}>
            <img src={Seller} width="64px" height="64px" />
          </Grid>
          <Grid sx={secondPartButton}>
            <Typography variant="h5">Clientes</Typography>
          </Grid>
        </Grid>
      </Link>
      <Link to="/pedidos/novo" style={{ textDecoration: 'none' }}>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={3}
          mt={1}
          mb={1}
          bgcolor="secondary.main"
          color="primary.contrastText"
          sx={styleMainButton}
        >
          <Grid sx={primaryPartButton}>
            <img src={Order} width="64px" height="64px" />
          </Grid>
          <Grid sx={secondPartButton}>
            <Typography variant="h5">Emissão de Pedidos</Typography>
          </Grid>
        </Grid>
      </Link>
      <Link to="/troca" style={{ textDecoration: 'none' }}>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={3}
          mt={1}
          mb={1}
          bgcolor="primary.success"
          color="primary.contrastText"
          sx={styleMainButton}
        >
          <Grid sx={primaryPartButton}>
            <img src={Replace} width="64px" height="64px" />
          </Grid>
          <Grid sx={secondPartButton}>
            <Typography variant="h5">Troca </Typography>
          </Grid>
        </Grid>
      </Link>
      <Link to="/pedidos" style={{ textDecoration: 'none' }}>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={3}
          mt={1}
          mb={1}
          bgcolor="primary.background2"
          color="primary.contrastText"
          sx={styleMainButton}
        >
          <Grid sx={primaryPartButton}>
            <img src={Search} width="64px" height="64px" />
          </Grid>
          <Grid sx={secondPartButton}>
            <Typography variant="h5">Acompanhar Pedidos</Typography>
          </Grid>
        </Grid>
      </Link>
      <Link to="/visitas" style={{ textDecoration: 'none' }}>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={3}
          mt={1}
          mb={1}
          bgcolor="primary.background2"
          color="primary.contrastText"
          sx={styleMainButton}
        >
          <Grid sx={primaryPartButton}>
            <img src={Schedule} width="64px" height="64px" />
          </Grid>
          <Grid sx={secondPartButton}>
            <Typography variant="h5"> Visitas </Typography>
          </Grid>
        </Grid>
      </Link>
      {/*   <Link to="/comissoes" style={{ textDecoration: 'none' }}>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={3}
          mt={1}
          mb={1}
          bgcolor="primary.warning"
          color="primary.contrastText"
          sx={styleMainButton}
        >
          <Grid sx={primaryPartButton}>
            <img src={Profit} width="64px" height="64px" />
          </Grid>
          <Grid sx={secondPartButton}>
            <Typography variant="h5"> Comissões </Typography>
          </Grid>
        </Grid>
  </Link>*/}

      <Link to="" style={{ textDecoration: 'none' }}>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={3}
          mt={1}
          mb={1}
          bgcolor="primary.error"
          color="primary.contrastText"
          onClick={confirmLogout}
          sx={styleMainButton}
        >
          <Grid sx={primaryPartButton}>
            <img src={Exit} width="64px" height="64px" />
          </Grid>
          <Grid sx={secondPartButton}>
            <Typography variant="h5"> Sair </Typography>
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );
}

export default Home;
