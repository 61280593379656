import React, { useState, useEffect, useRef } from 'react';

import {
  Box,
  Modal,
  Typography,
  Button,
  Grid,
  Divider,
  TextField,
  useMediaQuery,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

import apiCep from '../../api/apiCep';
import api from '../../api/api';
import { validarCNPJ, validaCPF } from '../../helpers/helpers';
import { SearchOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';

export default function ModalClienteView(props, { children }) {
  const { modalOpen, handleClose, dados } = props;
  const [loading, setLoading] = useState(false);
  const [errorCNPJCPF, setErrorCNPJCPF] = useState(false);

  const [codigocliente, setCodigocliente] = useState('0');
  const [tipo, setTipo] = useState(-1);
  const [razaoSocial, setRazaoSocial] = useState();
  const [nomeFantasia, setNomeFantasia] = useState();
  const [cnpj, setCnpj] = useState();
  const [insEstadual, setInsEstadual] = useState();
  const [cep, setCep] = useState();
  const [endereco, setEndereco] = useState();
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();
  const [estado, setEstado] = useState();
  const [eMail, setEmail] = useState();
  const [telefones, setTelefones] = useState();

  const isMobile = useMediaQuery('(min-width:801px)');
  const numberAddressInput = useRef();
  const cepInput = useRef();
  const id = localStorage.getItem('id');

  useEffect(() => {
    if (dados) {
      if (dados.codigocliente == undefined) {
        setCodigocliente(0);
      } else {
        setCodigocliente(dados.codigocliente);
      }
      setTipo(dados.tipo);
      setRazaoSocial(dados.razaoSocial);
      setNomeFantasia(dados.nomeFantasia);
      setCnpj(dados.cnpj);
      setInsEstadual(dados.insEstadual);
      setCep(dados.cep);
      setEndereco(dados.endereco);
      setNumero(dados.numero);
      setComplemento(dados.complemento);
      setBairro(dados.bairro);
      setCidade(dados.cidade);
      setEstado(dados.estado);
      setEmail(dados.eMail);
      setTelefones(dados.telefones);
    }
  }, [dados]);

  const style = isMobile
    ? {
        position: 'absolute',
        top: '50%',
        left: '50%',
        minWidth: '75%',
        maxWidth: '100%',
        height: '65%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        overflowX: 'hidden',
        boxShadow: 25,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        p: 4,
        m: 0,
      }
    : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        minWidth: '90%',
        maxWidth: '100%',
        height: '90%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        overflowY: 'auto',
        overflowX: 'hidden',
        boxShadow: 25,
        display: 'flex',
        flexDirection: 'column',

        borderRadius: '20px',
        p: 4,
        m: 0,
      };

  function editCpnj(e) {
    if (
      validarCNPJ(e.target.value) === false &&
      validaCPF(e.target.value) === false
    ) {
      toast.warning('Atenção CNPJ/CPF inválido.');
      setErrorCNPJCPF(true);
    } else {
      setErrorCNPJCPF(false);
    }
  }
  async function handleSearchCep() {
    await apiCep
      .get(`${cep}/json`)
      .then(({ data }) => {
        if (data.erro) {
          toast.error('CEP NÃO ENCONTRADO');
          cepInput.current.focus();
        } else {
          setEndereco(data.logradouro);
          setBairro(data.bairro);
          setCidade(data.localidade);
          setEstado(data.uf);
          numberAddressInput.current.focus();
        }
      })
      .catch((err) => {
        toast.error('erro' + err.message);
        cepInput.current.focus();
      });
  }

  function validaCliente() {
    if (
      tipo &&
      razaoSocial &&
      nomeFantasia &&
      cnpj &&
      cep &&
      endereco &&
      numero &&
      bairro &&
      cidade &&
      estado &&
      telefones &&
      !errorCNPJCPF
    ) {
      return true;
    }
  }

  async function handleSave() {
    setLoading(true);
    const saveData = {
      razao_social: razaoSocial,
      tipo: tipo,
      nome_fantasia: nomeFantasia,
      cep: cep,
      endereco: endereco,
      numero: numero,
      bairro: bairro,
      complemento: complemento,
      telefones: telefones,
      estado: estado,
      cidade: cidade,
      ins_estadual: insEstadual,
      cnpj: cnpj,
      contato: '',
      e_mail: eMail,
      cod_vendedor: id,
      app: 'SIM',
      codigo_cliente: codigocliente,
    };

    if (codigocliente == 0) {
      if (validaCliente() == true) {
        await api
          .post('/clientes/cliente', saveData)
          .then((res) => {
            handleClose();
            toast.success(res.data);
          })
          .catch((err) => {
            toast.error(err.response.data.error);
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error('Os campos devem ser todos preenchidos');
        setLoading(false);
      }
    } else {
      if (validaCliente() == true) {
        await api
          .post('/clientes/atualizaCliente', saveData)
          .then((res) => {
            handleClose();
            toast.success(res.data);
          })
          .catch((err) => {
            toast.error(err.response.data);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error('Os campos devem ser todos preenchidos');
        setLoading(false);
      }
    }
  }
  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            onClick={handleClose}
            style={{
              width: '25px',
              height: '25px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#ff5566',
              padding: '2px',
              marginBottom: '1%',
              borderRadius: '5px',
              boxShadow: '3px 3px #656565',
              color: '#ffff',
            }}
          >
            <Typography variant="h5">
              <strong>X</strong>
            </Typography>
          </div>
          {loading ? (
            <h1>Carregando...</h1>
          ) : (
            <>
              <Typography variant="h4">Cadastro de Cliente</Typography>
              <Divider />
              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                mt={5}
              >
                <Grid xs={12} sm={12} md={12} lg={1.5} mb={2} ml={1} mr={1}>
                  <TextField
                    id="codigo"
                    label="Código"
                    variant="outlined"
                    value={codigocliente ? codigocliente : 0}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={1.5} mb={2} ml={1} mr={1}>
                  <InputLabel id="demo-simple-select-label">
                    Tipo Cliente
                  </InputLabel>
                  <Select
                    fullWidth
                    value={tipo}
                    required
                    label="Age"
                    onChange={(e) => setTipo(e.target.value)}
                  >
                    <MenuItem value={-1} default>
                      SELECIONE UMA OPÇÃO
                    </MenuItem>
                    <MenuItem value={'FISICO'}>FISICO</MenuItem>
                    <MenuItem value={'JURIDICO'}>JURÍDICO</MenuItem>
                  </Select>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} mb={2} ml={1} mr={1}>
                  <TextField
                    id="razaosocial"
                    label="Razão Social"
                    variant="outlined"
                    value={razaoSocial}
                    required
                    onChange={(e) => setRazaoSocial(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4.25} mb={2} ml={1} mr={1}>
                  <TextField
                    id="nomefantasia"
                    label="Nome Fantasia"
                    required
                    variant="outlined"
                    value={nomeFantasia}
                    onChange={(e) => setNomeFantasia(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={2} mb={2} ml={1} mr={1}>
                  <TextField
                    id="cnpj"
                    label="C.N.P.J"
                    variant="outlined"
                    required
                    error={errorCNPJCPF}
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                    onBlur={(e) => editCpnj(e)}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={2.0} mb={2} ml={1} mr={1}>
                  <TextField
                    id="nomefantasia"
                    label="Inscrição Estadual"
                    variant="outlined"
                    value={insEstadual}
                    onChange={(e) => setInsEstadual(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={1.75}
                  mb={2}
                  ml={1}
                  mr={1}
                  display="flex"
                  flexDirection="row"
                >
                  <TextField
                    id="cep"
                    label="C.E.P"
                    variant="outlined"
                    required
                    inputRef={cepInput}
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                  />
                  <IconButton onClick={handleSearchCep}>
                    <SearchOutlined />
                  </IconButton>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} mb={2} ml={1} mr={1}>
                  <TextField
                    id="endereco"
                    label="Endereço"
                    variant="outlined"
                    required
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={1.3} mb={2} ml={1} mr={1}>
                  <TextField
                    id="numero"
                    label="Número"
                    variant="outlined"
                    value={numero}
                    required
                    onChange={(e) => setNumero(e.target.value)}
                    inputRef={numberAddressInput}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={2} mb={2} ml={1} mr={1}>
                  <TextField
                    id="complemento"
                    InputLabelProps={{ shrink: true }}
                    label="Complemento"
                    variant="outlined"
                    onChange={(e) => setComplemento(e.target.value)}
                    value={complemento}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} mb={2} ml={1} mr={1}>
                  <TextField
                    id="bairro"
                    InputLabelProps={{ shrink: true }}
                    label="Bairro"
                    required
                    onChange={(e) => setBairro(e.target.value)}
                    variant="outlined"
                    value={bairro}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} mb={2} ml={1} mr={1}>
                  <TextField
                    id="cidade"
                    label="Cidade"
                    required
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={1} mb={2} ml={1} mr={1}>
                  <TextField
                    id="estado"
                    label="Estado"
                    required
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    onChange={(e) => setEstado(e.target.value)}
                    value={estado}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={3} mb={2} ml={1} mr={1}>
                  <TextField
                    id="email"
                    label="E-mail"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    value={eMail}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={3} mb={2} ml={1} mr={1}>
                  <TextField
                    id="telefone"
                    label="Telefone"
                    variant="outlined"
                    required
                    onChange={(e) => setTelefones(e.target.value)}
                    value={telefones}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Button size={'large'} variant={'contained'} onClick={handleSave}>
                Salvar
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
