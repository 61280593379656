import Home from '../pages/Home/Home';
import Clientes from '../pages/Clientes/Clientes';
import Lista from '../pages/Pedidos/ListaPedidos/ListaPedidos';
import NovoPedido from '../pages/Pedidos/NovoPedido/NovoPedido';

import { Switch, Route, withRouter } from 'react-router-dom';

//Route personalizado para estabelecer a autenticacao
import { CustomRoute } from './CustomRoute';

//Pagina Login
import Login from '../pages/Login/Login';

//Layout da Dashboard
import Layout from '../Layouts/Layout';
import Visitas from '../pages/Visitas/Visitas';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import NovaTroca from '../pages/Troca/NovaTroca/NovaTroca';
import Comissoes from '../pages/Comissoes/Comissoes';

export default function Routes() {
  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/alterasenha" component={ResetPassword} isPrivate />
        <Layout>
          <Switch>
            <CustomRoute exact path="/" component={Home} isPrivate />
            <CustomRoute
              exact
              path="/clientes"
              component={Clientes}
              isPrivate
            />
            <CustomRoute exact path="/pedidos" component={Lista} isPrivate />
            <CustomRoute exact path="/troca" component={NovaTroca} isPrivate />
            <CustomRoute
              exact
              path="/comissoes"
              component={Comissoes}
              isPrivate
            />
            <CustomRoute
              path="/pedidos/novo"
              component={NovoPedido}
              isPrivate
            />
            <CustomRoute path="/visitas" component={Visitas} isPrivate />

            <CustomRoute path="*" isPrivate />
          </Switch>
        </Layout>
      </Switch>
    </>
  );
}
