import { useContext, useEffect, useState } from 'react';
import api from '../../../api/api';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  TableBody,
  TextField,
  Divider,
  Typography,
  useMediaQuery,
  Button,
  Input,
  Modal,
  Stack,
  NativeSelect,
} from '@mui/material';

import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { Context } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import history from '../../../routes/history';
import Spinner from '../../../components/Spinner/Spinner';

import { GridSearchIcon } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { InsertEmoticon, ShowChartTwoTone } from '@mui/icons-material';
export default function NovoPedido() {
  const [loading, setLoading] = useState(true);
  const [produtos, setProdutos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [codCliente, setCodCliente] = useState(-1);
  const [customerSelect, setCustomerSelect] = useState(-1);
  const [observacao, setObservacao] = useState();
  const [disabledField, setDisabledField] = useState(true);
  const [total, setTotal] = useState(0);
  const [somaTotal, setSomaTotal] = useState(0);
  const isMobile = useMediaQuery('(min-width:600px)');
  const [modalImagem, setModalImagem] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [fotoModal, setFotoModal] = useState();
  const [ultimoPedido, setUltimoPedido] = useState();
  const [serarchProductText, setSearchProductText] = useState();
  const [newListProduct, setNewListProduct] = useState();
  const { verificaToken } = useContext(Context);
  const [carregar, setCarregar] = useState(false);

  useEffect(() => {
    verificaToken();
    async function carregaClientes() {
      await api
        .post('clientes', {
          codigo: localStorage.getItem('id'),
        })
        .then((response) => {
          setClientes(response.data);
        })
        .catch((err) => {
          toast.error('Erro ao buscar dados dos  clientes');
        })
        .finally(() => setLoading(false));
    }
    carregaClientes();
  }, []);

  useEffect(() => {
    if (carregar) {
      setLoading(true);
      api
        .post('/produtos', {
          ativo: 'SIM',
          cliente: codCliente,
        })
        .then((response) => {
          setProdutos(response.data);
          setNewListProduct(response.data);
        })
        .catch((err) => {
          toast.error('Erro ao buscar dados dos  produtos');
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [carregar, codCliente]);

  function alteraQtde(i, event, item) {
    const index = produtos.findIndex(
      (produto) => produto.codigo_produto === item.codigo_produto
    );

    let valTotal;
    valTotal = total;
    const novaLista = [...produtos];
    novaLista[index].qtde = event.target.value.replace(/,/, '.');
    setProdutos(novaLista);
    if (novaLista[index].qtde !== '') {
      somaValor();
    }
    setSomaTotal(valTotal);
  }

  function somaValor() {
    let soma = 0;
    for (let i = 0; i < produtos.length; i++) {
      let qtd = parseFloat(produtos[i].qtde);
      if (qtd > 0) {
        soma =
          soma + parseFloat(produtos[i].venda1) * parseFloat(produtos[i].qtde);
      }
    }
    setSomaTotal(soma);
    setTotal(soma);
    console.log(soma);
  }

  function alteraValor(i, event, item) {
    const index = produtos.findIndex(
      (produto) => produto.codigo_produto === item.codigo_produto
    );
    const novaLista = [...produtos];
    novaLista[index].venda1 = event.target.value.replace(/,/, '.');
    setProdutos(novaLista);
    console.log(novaLista);
  }

  function handleChangeCustomers(e) {
    setCarregar(true);
    setCustomerSelect(e.target.value);
    setCodCliente(clientes[e.target.value].codigoCliente);
    setDisabledField(false);
  }

  async function handSalvar(e) {
    e.preventDefault();

    setLoading(true);

    if (codCliente === -1) {
      toast.error('Selecione um cliente valido');
    } else {
      let itensVendas = {};
      let produtosselecionado = [];
      let produtosselecionado2 = [];
      for (var i = 0; i < produtos.length; i++) {
        if (
          /*parseFloat(produtos[i].qtde.replace(/,/, ".")) > 0*/ parseFloat(
            produtos[i].qtde
          ) > 0
        ) {
          itensVendas.codigoVenda = 0;
          itensVendas.codigoProduto = produtos[i].codigo_produto;
          itensVendas.codigoCliente = codCliente;
          itensVendas.codigoVendedor = localStorage.getItem('id');
          itensVendas.valor = parseFloat(produtos[i].venda1); //parseFloat(produtos[i].venda1.replace(/,/, "."));
          itensVendas.qtde = parseFloat(produtos[i].qtde); //parseFloat(produtos[i].qtde.replace(/,/, "."));
          itensVendas.status = 'PEDIDO';
          itensVendas.transmitido = 'SIM';
          itensVendas.desconto = '0.00';
          itensVendas.obs = observacao;
          itensVendas.codigoVendaSv = '0';
          itensVendas.emissao = '';
          itensVendas.tipo = 'VENDA';
          produtosselecionado.push({ ...itensVendas });
        }
      }
      produtosselecionado2 = {
        itens: produtosselecionado,
      };

      await api
        .post('pedidos', produtosselecionado2)
        .then((response) => {
          toast.success('Pedido Salvo com Sucesso');
          setLoading(false);
          setInterval(() => {
            history.push('/pedidos');
            history.go();
          }, 2500);
        })
        .catch((err) => {
          toast.error('Erro ao salvar dados' + err);
          console.log(err);
          setLoading(false);
          setDisabledField(true);
        });
    }
  }
  const handleFocus = (event) => {
    const { target } = event;
    const extensionStarts = target.value.lastIndexOf('.');
    target.focus();
    target.setSelectionRange(0, extensionStarts);
  };

  const Styles = isMobile
    ? {
        grid: { display: 'flex', flexDirection: 'row', maxWidth: '90vw' },
        table: {},
        mainGrid: {
          display: ' flex',
          flexDirection: 'column',
          textAlign: 'center',
        },
      }
    : {
        grid: { display: 'flex', flexDirection: 'column' },
        table: { maxWidth: '90vw' },
        mainGrid: {
          display: ' flex',
          flexDirection: 'column',
          textAlign: 'center',
          maxWidth: '90vw',
        },
        formulario: { maxWidth: 200 },
        largerCell: { textAlign: 'left', maxWidth: 80 },
        smallCell: { textAlign: 'center', minwidth: 10, maxWidth: 10 },

        midCell: { maxWidth: 20 },
        smallField: { minWidth: 40 },

        mediumField: { minWidth: 55 },
      };

  function ModalImage(props) {
    const { modalOpen, foto } = props;
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 310,
      bgcolor: 'background.paper',
      border: '0px solid #000',
      boxShadow: 24,
      p: 1,
    };

    return (
      <Modal
        open={modalOpen}
        hideBackdrop
        onClick={() => setModalImagem(!modalImagem)}
      >
        <Box sx={style}>
          <img
            src={`data:image/jpeg;base64,${foto}`}
            width="310"
            height="310"
            alt="Produto"
          />
        </Box>
      </Modal>
    );
  }
  function handleOpenModal(foto) {
    if (foto !== 'SEM FOTO') {
      setFotoModal(foto);
      setModalImagem(true);
    }
  }
  function handleOpenModalDetails(ultimo) {
    if (ultimo) {
      setUltimoPedido(ultimo);
      setModalDetails(true);
    }
  }
  function showSelectedItens() {
    if (filterActive === false) {
      const listProducts = produtos.filter((produto) => produto.qtde > 0);
      setNewListProduct(listProducts);
      setFilterActive(true);
    } else if (filterActive === true) {
      setFilterActive(false);
      setNewListProduct(produtos);
    }
  }
  function handleSearchProduto(item) {
    setSearchProductText(item);
    const filtredList = produtos.filter((list) => {
      if (
        list.descricao_produto.toLowerCase().indexOf(item.toLocaleLowerCase()) >
        -1
      ) {
        return true;
      } else {
        return false;
      }
    });
    setNewListProduct(filtredList);
  }

  function ModalDetails(props) {
    const { ultimo, modalOpen } = props;

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 300,
      bgcolor: 'background.paper',
      border: '0px solid #000',
      boxShadow: 24,
      p: 1,
    };

    return (
      <>
        <Modal
          open={modalOpen}
          hideBackdrop
          onClick={() => setModalDetails(!modalDetails)}
        >
          <Box sx={style}>
            <Typography variant="h6" gutterBottom component="div">
              Ultima Compra
            </Typography>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Box>Data</Box> <Box>Valor</Box>
              <Box> QTDE</Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',

                justifyContent: 'center',
              }}
            >
              {ultimo}
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
  function apertaTecla(e) {
    if (e.key == 'Enter' || e.charCode == 13) {
      e.preventDefault();
    }
  }
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <ModalDetails modalOpen={modalDetails} ultimo={ultimoPedido} />
      <ModalImage foto={fotoModal} modalOpen={modalImagem} />
      <Grid sm={12} md={12} lg={12} sx={Styles.mainGrid}>
        <Typography variant="h3">Novo Pedido </Typography>
        <Grid
          sm={12}
          md={12}
          lg={6}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            href="/"
            variant="contained"
            startIcon={<ArrowBackTwoToneIcon />}
          >
            Voltar
          </Button>
          <Button
            onClick={() => showSelectedItens()}
            variant="contained"
            startIcon={<ShowChartTwoTone />}
          >
            {filterActive ? 'Todos os Itens' : 'Itens Selecionados'}
          </Button>
        </Grid>
        <Divider />

        <form
          onSubmit={handSalvar}
          styles={Styles.formulario}
          onKeyPress={apertaTecla}
        >
          <Grid sm={12} md={6} lg={6} mt={2} mb={2}>
            <Grid
              sm={12}
              md={6}
              lg={6}
              mr={2}
              mt={2}
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <Stack spacing={2} sx={{ width: '100%' }}>
                <NativeSelect
                  value={customerSelect}
                  label="Selecione o cliente"
                  onChange={(e) => handleChangeCustomers(e)}
                >
                  <option value={-1}>Selecione o Cliente </option>
                  {clientes.map((cliente, index) => {
                    return (
                      <option key={index} value={index}>
                        {cliente.nomeFantasia}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Stack>
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              mr={2}
              mt={2}
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <TextField
                label="Observação"
                type="text"
                fullWidth
                value={observacao}
                disabled={disabledField}
                onChange={(e) => setObservacao(e.target.value)}
              />
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              mr={2}
              mt={2}
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <TextField
                label="Busca Produto"
                type="text"
                fullWidth
                disabled={disabledField}
                value={serarchProductText}
                onChange={(e) => handleSearchProduto(e.target.value)}
              />
            </Grid>
          </Grid>

          <Table sx={{ maxWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={1}
                  sx={{ width: 5, padding: 0 }}
                ></TableCell>
                {/*}  <TableCell colSpan={1} sx={{ width: 5, padding: 0 }}>
                    Id*/}
                <TableCell colSpan={2} sx={{ padding: 0 }}>
                  Produto
                </TableCell>
                <TableCell colSpan={1}>UN</TableCell>
                <TableCell colSpan={1}>ValorR$</TableCell>
                <TableCell colSpan={1}>Qtde</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newListProduct ? (
                newListProduct.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell colSpan={1} sx={{ padding: 1 }}>
                        {item.foto ? (
                          <InsertEmoticon
                            style={{ cursor: 'pointer', margin: 1 }}
                            onClick={() => handleOpenModal(item.foto)}
                          />
                        ) : (
                          <></>
                        )}
                        {item.ultimo ? (
                          <GridSearchIcon
                            style={{ cursor: 'pointer', margin: 1 }}
                            onClick={() => handleOpenModalDetails(item.ultimo)}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>

                      {/*} <TableCell colSpan={1} sx={{ width: 5, padding: 1 }}>
                          {item.codigo_produto}
                          </TableCell>*/}
                      <TableCell colSpan={2} sx={{ padding: 0 }}>
                        {item.descricao_produto}
                      </TableCell>
                      <TableCell colSpan={1}>{item.un}</TableCell>
                      <TableCell colSpan={1} sx={{ padding: 0, width: 10 }}>
                        <Input
                          type="text"
                          sx={Styles.smallField}
                          value={item.venda1}
                          required
                          onChange={(e) => alteraValor(index, e, item)}
                          size="small"
                          onBlur={somaValor}
                          onFocus={handleFocus}
                        />
                      </TableCell>
                      <TableCell sx={{ padding: 0, width: 10 }}>
                        <Input
                          type="text"
                          sx={Styles.smallField}
                          value={item.qtde}
                          onChange={(e) => alteraQtde(index, e, item)}
                          onBlur={somaValor}
                          onFocus={handleFocus}
                          required
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <></>
              )}
              <TableRow>
                <TableCell colSpan={2} align="right">
                  <Typography variant="h5" sx={{ color: 'primary.error' }}>
                    <strong>Total do Pedido</strong>
                  </Typography>
                </TableCell>
                <TableCell colSpan={3} align="left">
                  <Typography variant="h5" sx={{ color: 'primary.error' }}>
                    <strong>
                      {somaTotal.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </strong>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Grid
            sm={12}
            md={12}
            lg={12}
            mr={2}
            mt={3}
            mb={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '600',
            }}
          >
            <Button
              startIcon={<SaveTwoToneIcon />}
              fullWidth
              variant="contained"
              size="large"
              disabled={disabledField}
              type="submit"
            >
              Salvar Pedido
            </Button>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
