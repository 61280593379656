import { useState, useEffect } from 'react';

import {
  Grid,
  Typography,
  Divider,
  Button,
  IconButton,
  CircularProgress,
  ThemeProvider,
  Tooltip,
  FormControl,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Modal,
} from '@mui/material';

import { toast } from 'react-toastify';

import api from '../../api/api';

import { DataGrid } from '@mui/x-data-grid';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';

import PlaylistAddCheckTwoToneIcon from '@mui/icons-material/PlaylistAddCheckTwoTone';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';

import { theme } from '../../theme/theme';

import moment from 'moment';
import NewVisita from './Modais/ModalNewVisitaView/NewVisita';
import { ReplayCircleFilledTwoTone } from '@mui/icons-material';

export default function Visitas() {
  const [openModalView, setOpenModalView] = useState(false);
  const [visitaSelecionada, setVisitaSelecionada] = useState();
  const [openModalReagenda, setOpenModalReagenda] = useState(false);
  const [openModalConfirma, setOpenModalConfirma] = useState(false);
  const [openModalCancela, setOpenModalCancela] = useState(false);
  const [descricao, setDescricao] = useState();
  const [novaData, setNovaData] = useState();
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadLista() {
      const user = localStorage.getItem('id');
      await api
        .post('/visitas/busca', { codigo: user })
        .then(({ data }) => {
          console.log(data);
          setLista(data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
    }
    loadLista();
    setLoading(false);
  }, [loading]);

  if (lista.length == 0) {
    return (
      <>
        <NewVisita open={openModalView} onClose={handleModalViewFalse} />
        <Grid
          container
          mt={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography textAlign="center" mb={4} variant="h4">
            Visitas Agendadas
          </Typography>
          <Grid sx={{ display: 'flex', sm: 12, md: 6, lg: 6 }}>
            <Button
              href="/"
              variant="contained"
              startIcon={<ArrowBackTwoToneIcon />}
            >
              Voltar
            </Button>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            sx={{ textAlign: 'center' }}
          >
            <Button
              variant="contained"
              onClick={handleModalView}
              size="large"
              fullWidth
            >
              <AddTwoToneIcon /> Nova Visita
            </Button>
          </Grid>
          <Divider />
          ''
          <Grid>
            <Typography variant="h5">NÃO EXISTE VISITAS AGENDADAS </Typography>
          </Grid>
          <Divider />
        </Grid>
      </>
    );
  }

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  const columns = [
    {
      field: 'Data',
      headerName: 'Data',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data',
    },

    {
      field: 'Status',
      headerName: 'Status',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data',
    },
    {
      field: 'Solicitante',
      headerName: 'Solicitante',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data',
    },
    {
      field: 'Nome',
      headerName: 'Nome Fantasia',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data',
    },

    {
      field: 'Obs',
      headerName: 'Observação',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data',
    },

    {
      field: 'acao',
      headerName: '',
      width: 150,
      disableClickEventBubbling: true,
      sortable: false,

      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes codigo={params.row.Codigo} />
          </div>
        );
      },
    },
  ];
  const rows = lista.map((item, index) => {
    return {
      id: `${index}`,
      Codigo: `${item.vis_codigo}`,
      Data: `${moment(item.vis_data).add(3, 'hours').format('DD/MM/YYYY')}`,
      Status: `${item.vis_status}`,
      Solicitante: `${item.vis_solicitante}`,
      Nome: `${item.nome_fantasia}`,
      Obs: `${item.vis_obs}`,
    };
  });

  const Acoes = ({ index, codigo }, props) => {
    return (
      <>
        <Tooltip arrow title="Cancelar Visita">
          <IconButton
            color="secondary"
            aria-label="cancelar visita"
            onClick={() => cancelaVisita(codigo)}
          >
            <CancelPresentationTwoToneIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Reagendar Visita">
          <IconButton
            color="success"
            aria-label="Reagendar visita"
            onClick={() => reagendaVisita(codigo)}
          >
            <ReplayCircleFilledTwoTone />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Visita Realizada">
          <IconButton
            color="success"
            aria-label="baixar visita"
            onClick={() => confirmarVisita(codigo)}
          >
            <PlaylistAddCheckTwoToneIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  function handleModalView(item) {
    setLoading(true);
    setOpenModalView(true);
    setLoading(false);
  }

  function handleModalViewFalse() {
    setLoading(true);
    setOpenModalView(false);
    setLoading(false);
  }

  function cancelaVisita(codigo) {
    setVisitaSelecionada(codigo);
    setOpenModalCancela(true);
  }

  function CancelarVisita(props) {
    const { openModalCancela } = props;
    const style = {
      Modal: {
        position: 'absolute',
        justifyContent: 'space-around',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      },
    };
    const handleClose = () => {
      setOpenModalCancela(false);
      setDescricao('');
    };

    async function confirma() {
      await api
        .put('/visitas/cancel', {
          codigo: visitaSelecionada,
          motivo: descricao,
        })
        .then(() => {
          toast.success('Visita cancelada com sucesso.');
          setLoading(true);
          handleClose();
        })
        .catch((err) => {
          toast.error('Falha na operação. - ' + err);
        });
    }
    return (
      <Modal open={openModalCancela} onClose={handleClose} hideBackdrop>
        <Box sx={style.Modal}>
          <Typography variant="h6">
            Digite o Histórico de cancelamento
          </Typography>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Histórico "
            defaultValue={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />

          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={confirma}>Confirmar</Button>
        </Box>
      </Modal>
    );
  }

  function reagendaVisita(codigo) {
    setVisitaSelecionada(codigo);
    setOpenModalReagenda(!openModalReagenda);
  }

  function ReagendarVisita(props) {
    const { openModalReagenda } = props;

    const handleClose = () => {
      setOpenModalReagenda(false);
      setDescricao('');
    };
    async function confirma() {
      console.log(visitaSelecionada);
      await api
        .put('/visitas/reagenda', {
          codigo: visitaSelecionada,
          data: novaData,
        })
        .then(() => {
          toast.success('Visita Reagendada  com sucesso.');
          setLoading(true);
          handleClose();
        })
        .catch((err) => {
          toast.error('Falha na operação. - ' + err);
        });
    }

    return (
      <div>
        <Dialog
          open={openModalReagenda}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Selecione a nova data para a visita '}
          </DialogTitle>
          <DialogContent>
            <FormControl>
              <TextField
                id="date"
                label="Data Inicial"
                type="date"
                value={novaData}
                defaultValue={novaData}
                onChange={(e) => setNovaData(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Cancelar
            </Button>
            <Button onClick={confirma}>Confirmar</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function confirmarVisita(codigo) {
    setVisitaSelecionada(codigo);
    setOpenModalConfirma(!openModalConfirma);
  }

  function ConfirmarVisita(props) {
    const { openModalConfirma } = props;
    const style = {
      Modal: {
        position: 'absolute',
        justifyContent: 'space-around',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      },
    };
    const handleClose = () => {
      setOpenModalConfirma(false);
      setDescricao('');
    };

    async function confirma() {
      await api
        .put('/visitas/confirma', {
          codigo: visitaSelecionada,
          motivo: descricao,
        })
        .then(() => {
          toast.success('Visita confirmada com sucesso.');
          setLoading(true);
          handleClose();
        })
        .catch((err) => {
          toast.error('Falha na operação. - ' + err);
        });
    }
    return (
      <Modal open={openModalConfirma} onClose={handleClose} hideBackdrop>
        <Box sx={style.Modal}>
          <Typography variant="h6">
            Digite o Histórico de confirmação
          </Typography>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Histórico "
            defaultValue={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />

          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={confirma}>Confirmar</Button>
        </Box>
      </Modal>
    );
  }

  return (
    <>
      {visitaSelecionada ? (
        <>
          <ReagendarVisita openModalReagenda={openModalReagenda} />
          <ConfirmarVisita openModalConfirma={openModalConfirma} />
          <CancelarVisita openModalCancela={openModalCancela} />
        </>
      ) : (
        <></>
      )}
      <ThemeProvider theme={theme}>
        <NewVisita open={openModalView} onClose={handleModalViewFalse} />

        <Grid
          container
          mt={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography textAlign="center" mb={4} variant="h4">
            Visitas Agendadas
          </Typography>
          <Grid sx={{ display: 'flex', sm: 12, md: 6, lg: 6 }}>
            <Button
              href="/"
              variant="contained"
              startIcon={<ArrowBackTwoToneIcon />}
            >
              Voltar
            </Button>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            sx={{ textAlign: 'center' }}
          >
            <Button
              variant="contained"
              onClick={handleModalView}
              size="large"
              fullWidth
            >
              <AddTwoToneIcon /> Nova Visita
            </Button>
          </Grid>
          <Divider />
          <Grid
            sx={{ sm: 12, md: 10, lg: 10 }}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            {rows ? (
              <DataGrid
                autoHeight
                loading={rows.length === 0}
                columns={columns}
                rows={rows}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
              />
            ) : (
              <h1>Nao Existe Visitas Cadastradas</h1>
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
