import { useContext, useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import api from '../../../api/api';
import {
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
  Button,
  Divider,
} from '@mui/material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import PageviewTwoToneIcon from '@mui/icons-material/PageviewTwoTone';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Context } from '../../../contexts/AuthContext';
import ModalPedidoView from '../../../components/ModalPedidoView/ModalPedidoView';
import { ShareTwoTone } from '@mui/icons-material';
export default function Lista() {
  const [loading, setLoading] = useState(true);
  const [pedidos, setPedidos] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [enviaModal, setEnviaModal] = useState();
  const isMobile = useMediaQuery('(min-width:600px)');
  const { verificaToken } = useContext(Context);
  const confirm = useConfirm();

  useEffect(() => {
    async function loadPedidos() {
      await api
        .post('pedidos/busca', {
          pedido: '0',
          cliente: '0',
          vendedor: localStorage.getItem('id'),
        })
        .then(({ data }) => {
          setPedidos(data);
          console.log(data);
        })
        .catch((err) => {
          alert(err);
          toast.error('Erro ao buscar dados');
        });
    }
    setLoading(false);
    loadPedidos();
  }, [loading]);

  const Acoes = ({ allOrders }) => {
    return (
      <>
        <IconButton
          color="error"
          aria-label="cancelar visita"
          onClick={() => confirmDeletePedido(allOrders)}
        >
          <DeleteForeverTwoToneIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="cancelar visita"
          onClick={() => handleOpenModal(allOrders)}
        >
          <PageviewTwoToneIcon />
        </IconButton>
        <IconButton
          color="success"
          onClick={() => {
            shareData();
          }}
        >
          <ShareTwoTone />
        </IconButton>
      </>
    );
  };
  async function shareData() {
    if (navigator.share) {
      await navigator
        .share({
          title: 'Pedido de venda',
          text: 'qualquer texto',
        })
        .then(() => alert('Pedido compartilhado'))
        .catch(() => alert('erro'));
    }
  }
  const newPedidos = pedidos.map((pedido, index) => {
    const {
      codigo_venda,
      codigo_venda_sv,
      emissao,
      razao_social,
      status,
      obs,
      total,
    } = pedido;
    const formatedEmissao = moment(emissao).format('DD/MM/YY');
    return {
      id: index,
      codigo_venda: codigo_venda,
      codigo_venda_sv: codigo_venda_sv,
      razao_social: razao_social,
      emissao: formatedEmissao,
      obs: obs,
      status: status,
      total: total.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    };
  });
  const columns = [
    {
      field: 'razao_social',
      headerName: 'Razão Social',
      width: 170,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Numero do Pedido ',
    },
    {
      field: 'emissao',
      headerName: 'Emissao',
      width: 80,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Pedido ',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Pedido ',
    },
    {
      field: 'acao',
      headerName: '',
      width: 150,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes allOrders={params.row} />
          </div>
        );
      },
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  function confirmDeletePedido(pedido) {
    if (pedido.codigo_venda_sv == 0) {
      confirm({
        title: 'Confirme a Operação',
        description: `Deseja APAGAR o pedido ${pedido.codigo_venda}  ?`,
        cancellationText: 'Cancelar',
        confirmationText: 'Aceitar',
      }).then(() => {
        deletePedido({ pedido: pedido.codigo_venda });
      });
    } else {
      confirm({
        title: 'Confirme a Operação',
        description: `Status do pedido não permite a exclusão!`,
        confirmationText: 'Aceitar',
      });
    }
  }

  async function deletePedido(item) {
    await api
      .post('pedidos/apagar', {
        pedido: item.pedido,
      })
      .then((response) => {
        toast.success('Pedido apagado com sucesso! ');
      })
      .catch((err) => {
        toast.error('Erro ao apagar dados ');
      });
    setLoading(true);
  }
  function handleOpenModal(dados) {
    setEnviaModal(dados);
    setModalOpen(true);
  }

  function handleModalClose(props) {
    setEnviaModal('');
    setModalOpen(false);
  }
  return (
    <>
      <ModalPedidoView
        dados={enviaModal}
        modalOpen={modalOpen}
        handleClose={handleModalClose}
      />
      <Grid>
        <Typography textAlign="center" mb={4} variant="h4">
          Lista de Pedidos
        </Typography>
        <Grid sm={12} md={6} lg={6} sx={{ display: 'flex', mb: 2 }}>
          <Button
            href="/"
            variant="contained"
            startIcon={<ArrowBackTwoToneIcon />}
          >
            Voltar
          </Button>
        </Grid>
        <Divider />
        {pedidos.length !== 0 ? (
          <DataGrid
            autoHeight
            loading={newPedidos.length === 0}
            rows={newPedidos}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        ) : (
          <Grid
            justifyContent="center"
            alignItems="center"
            sm={12}
            md={6}
            lg={6}
            sx={{ display: 'flex', mb: 2 }}
          >
            {' '}
            <Typography variant="h3">Não Existe Pedidos Cadastrados</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
