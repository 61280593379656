import React, { createContext, useState, useEffect } from 'react';
import api from '../api/api';
import history from '../routes/history';
import { toast } from 'react-toastify';

const Context = createContext();

function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch (e) {
        return null;
      }
    };
    const token = JSON.parse(localStorage.getItem('token'));

    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        setAuthenticated(false);
        localStorage.removeItem('token');
        setLoading(false);
      } else {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        setAuthenticated(true);
        setLoading(false);
      }
    } else {
      setAuthenticated(false);
      localStorage.removeItem('token');
    }
    setLoading(false);
  }, []);

  async function handleLogin(user, pass) {
    const {
      data: { token, id },
    } = await api.post('/auth', {
      email: `${user}`,
      password: `${pass}`,
    });

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('id', id);
      history.push('/');
      history.go();
    } else {
      toast.error('Usuario ou senha Invalidos ');
      setAuthenticated(false);
      localStorage.removeItem('token');
      setLoading(false);
    }
  }

  async function handleCheckUser(user, pass) {
    await api
      .post('/usuario/consulta', {
        email: `${user}`,
        senha: `${pass}`,
      })
      .then(({ data }) => {
        if (data.length != 0) {
          setAuthenticated(true);
          localStorage.setItem('user', user);
          history.push('/alterasenha');
          history.go();
        } else {
          setAuthenticated(false);
          localStorage.removeItem('token');
          history.push('/login');

          history.go();
        }
      });
  }
  async function handleLogout() {
    setLoading(true);
    setAuthenticated(true);
    localStorage.removeItem('token');
    api.defaults.headers.Authorization = undefined;
    history.push('/login');
    history.go();
  }
  async function verificaToken() {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch (e) {
        return null;
      }
    };

    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.removeItem('token');
        setAuthenticated(false);
        history.push('/login');
        history.go();
      } else {
        setAuthenticated(true);
      }
    } else {
      setAuthenticated(false);
      localStorage.removeItem('token');
      history.push('/login');
      history.go();
    }
    setLoading(false);
  }

  return (
    <Context.Provider
      value={{
        authenticated,
        handleLogin,
        setLoading,
        loading,
        handleLogout,
        handleCheckUser,
        verificaToken,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
