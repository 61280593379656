import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Context } from '../contexts/AuthContext';
import history from '../routes/history';
import checkToken from '../services/checkToken';

export function CustomRoute({ component: Component, isPrivate, ...rest }) {
  const { authenticated, loading, verificaToken } = useContext(Context);
  verificaToken();
  if (loading) {
    return <h1>Carregando...</h1>;
  }
  if (!authenticated && isPrivate) {
    history.push('/login');
    history.go();
  }

  return (
    <Route {...rest} render={({ location }) => <Component {...location} />} />
  );
}
