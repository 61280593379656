import { Grid } from '@mui/material';
import Logo from '../images/logo.png';

function Layout({ children }) {
  return (
    <>
      {' '}
      <Grid
        xs={12}
        sm={12}
        md={6}
        lg={3}
        mt={2}
        mb={2}
        bgcolor="#ffff"
        color="primary.contrastText"
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '15vh',
          borderRadius: '20px',
          textAlign: 'center',
        }}
      >
        <img src={Logo} width={'300'} />
      </Grid>
      <Grid>{children}</Grid>
    </>
  );
}

export default Layout;
