import { useState, useEffect } from 'react';

import api from '../../../../api/api';
import { toast } from 'react-toastify';

import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Stack,
  CircularProgress,
  Typography,
  Modal,
  Grid,
  IconButton,
  Grow,
  TextField,
  Button,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  NativeSelect,
} from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

function NewVisita(props) {
  const momentPTBR = moment.locale('pt-br');
  let dataHoje = moment().add(1, 'days');
  const [visdata, setVisData] = useState(dataHoje);
  const [clientes, setClientes] = useState();
  const [clienteSelecionado, setClienteSelecionado] = useState('');
  const [visSolicitante, setVissolicitante] = useState('');
  const [visobs, setVisobs] = useState('');
  const id = localStorage.getItem('id');
  const [loading, setLoading] = useState(false);
  const { onClose } = props;

  useEffect(() => {
    const id = localStorage.getItem('id');
    api
      .post('/clientes', { codigo: id })
      .then(({ data }) => {
        setClientes(data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error('Erro ao Carregar Clientes' + err);
        setLoading(false);
      });
  }, []);

  async function handSalve(e) {
    e.preventDefault();
    await api
      .post('/visitas', {
        visCliente: clienteSelecionado,
        visData: visdata,
        visSolicitante: visSolicitante,
        visVendedor: id,
        visStatus: 'Pendente',
        visObs: visobs,
      })
      .then(() => {
        toast.success('Agenda salva com sucesso.');
        onClose(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Falha na operação (' + err + ').');
      });
  }

  if (loading) {
    return (
      <div className="filme-info">
        <h1>Carregando Agenda ...</h1>
      </div>
    );
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    height: '90vh',
    p: 2,
    zIndex: 11111,
  };

  return (
    <>
      <Modal sx={{ overflow: 'scroll' }} hideBackdrop {...props}>
        <Grid sx={style}>
          <Grid mb={3}>
            <IconButton onClick={onClose}>
              <CancelSharpIcon color="warning" />
            </IconButton>
          </Grid>
          <Grow in>
            <Grid sm={12} md={12} lg={12} mt={2} mb={5}>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{ textAlign: ' center' }}
              >
                <Typography variant="h4">Solicitar Visita</Typography>
              </Grid>

              {loading ? (
                <Stack spacing={20} direction="row" size={400}>
                  <CircularProgress color="primary" disableShrink />
                </Stack>
              ) : (
                <Grid
                  sm={12}
                  md={5}
                  lg={12}
                  mt={2}
                  mr={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <form onSubmit={handSalve} style={{ width: '100%' }}>
                    <Grid sx={12} sm={12} md={12} lg={12} mt={2} mr={1}>
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        locale={'ptBR'}
                      >
                        <DatePicker
                          label="Data Agendada"
                          value={visdata}
                          onChange={(data) => {
                            setVisData(data);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid sx={12} sm={12} md={12} lg={12} mt={2} mr={1}>
                      <FormControl fullWidth>
                        <InputLabel id="label">Cliente</InputLabel>
                        <Select
                          native
                          labelId="label"
                          id="select"
                          value={clienteSelecionado}
                          label="Cliente"
                          onChange={(e) => {
                            setClienteSelecionado(e.target.value);
                          }}
                        >
                          <option value={-1}>Selecione um Cliente</option>
                          {clientes ? (
                            clientes.map((cliente, index) => {
                              return (
                                <option
                                  key={index}
                                  value={cliente.codigoCliente}
                                >
                                  {cliente.nomeFantasia}
                                </option>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid sx={12} sm={12} md={12} lg={12} mt={2} mr={1}>
                      <TextField
                        fullWidth
                        label="Solicitante"
                        type="text"
                        value={visSolicitante}
                        onChange={(e) => setVissolicitante(e.target.value)}
                      />
                    </Grid>
                    <Grid sx={12} sm={12} md={12} lg={12} mt={2} mr={1}>
                      <TextField
                        fullWidth
                        label="Observação"
                        type="text"
                        value={visobs}
                        onChange={(e) => setVisobs(e.target.value)}
                      />
                    </Grid>

                    <Grid sx={12} sm={12} md={12} lg={12} mt={5} mr={0}>
                      <Button fullWidth variant="contained" type="submit">
                        <>
                          <SaveTwoToneIcon />
                          <Typography variant="h6" fullWidth>
                            Cadastrar
                          </Typography>
                        </>
                      </Button>
                    </Grid>
                  </form>
                </Grid>
              )}
            </Grid>
          </Grow>
        </Grid>
      </Modal>
    </>
  );
}

export default NewVisita;
