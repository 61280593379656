import { useContext, useEffect, useState } from 'react';
import api from '../../api/api';
import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import ModalClienteView from '../../components/ModalClienteView/ModalClienteView';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import { Context } from '../../contexts/AuthContext';
import { AddTwoTone, ArrowBack } from '@mui/icons-material';
export default function Clientes() {
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [enviaModal, setEnviaModal] = useState();

  const { verificaToken } = useContext(Context);

  useEffect(() => {
    async function carregaClierntes() {
      const id = localStorage.getItem('id');
      await api
        .post('/clientes', { codigo: id })
        .then(({ data }) => {
          setClientes(data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error('Erro ao Carregar Clientes' + err);
          setLoading(false);
        });
    }

    carregaClierntes();
  }, [modalOpen]);

  if (loading) {
    return <Spinner />;
  }

  function handleOpenModal(dados) {
    if (dados) {
      setEnviaModal(dados.row);
    } else {
      setEnviaModal({ dados: 'sem dados' });
    }
    setModalOpen(true);
  }

  function handleModalClose() {
    setEnviaModal({});
    setModalOpen(false);
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const newCliente = clientes.map((cliente, index) => {
    const {
      razaoSocial,
      nomeFantasia,
      codigoCliente,
      tipo,
      bairro,
      cep,
      cidade,
      cnpj,
      complemento,
      contato,
      eMail,
      endereco,
      estado,
      insEstadual,
      numero,
      telefones,
    } = cliente;
    return {
      id: index,
      codigocliente: `${codigoCliente}`,
      tipo: tipo,
      razaoSocial: razaoSocial,
      nomeFantasia: nomeFantasia,
      cnpj: cnpj,
      insEstadual: insEstadual,
      cep: cep,
      endereco: endereco,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      estado: estado,
      contato: contato,
      eMail: eMail,
      telefones: telefones,
    };
  });
  const columns = [
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 300,
      disableColumnMenu: true,
      sortable: false,
      description: 'CNPJ ',
    },
    {
      field: 'nomeFantasia',
      headerName: 'Nome Fantasia',
      width: 300,
      disableColumnMenu: true,
      sortable: false,
      description: 'Nome Fantasia ',
    },
    {
      field: 'razaoSocial',
      headerName: 'Razão Social',
      width: 300,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Numero do Pedido ',
    },
  ];

  return (
    <>
      <ModalClienteView
        dados={enviaModal}
        modalOpen={modalOpen}
        handleClose={handleModalClose}
      />
      <Typography textAlign="center" mb={4} variant="h4">
        Clientes Cadastrados
      </Typography>
      <Grid md={12} lg={12} display={'flex'} justifyContent={'space-around'}>
        <Button
          variant="contained"
          size="large"
          href="/"
          startIcon={<ArrowBack />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={() => handleOpenModal()}
          startIcon={<AddTwoTone />}
        >
          Novo Cliente
        </Button>
      </Grid>

      <div style={{ height: 400, width: '99%' }}>
        <DataGrid
          autoHeight
          onCellClick={(dados) => handleOpenModal(dados)}
          loading={newCliente.length === 0}
          rows={newCliente}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
}
